<template>
  <div class="d-flex flex-row">
    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px" id="kt_profile_aside">
      <!-- <div class="card card-custom card-stretch"> -->
      <div class="">
        <!-- <div class="d-flex justify-content-end">
            <KTDropdown2></KTDropdown2>
          </div> -->
        <div class="d-flex align-items-center">
          <!-- <div
              class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
            >
              <div
                class="symbol-label"
                :style="{
                  backgroundImage: `url(${picture})`
                }"
              ></div>
            </div> -->
          <div>
            <!-- <a
                class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                >{{ getFullName }}</a
              > -->
          </div>
        </div>
        <!--end::User-->
        <!--begin::Contact-->
        <!-- <div class="py-9">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">Email:</span>
              <a class="text-muted text-hover-primary">{{
                currentUser.userData.email
              }}</a>
            </div>
          </div> -->
        <!--end::Contact-->

        <!--begin::Nav-->
        <div class="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist">
          <div class=" navi-item mb-2">
            <a class="navi-link py-4" @click="setActiveTab" style="cursor:pointer" data-tab="0" data-toggle="tab"
              role="tab" aria-selected="false" ref="tabs">
              <span class="navi-icon mr-2">
                <span class="svg-icon">
                  <inline-svg src="media/svg/icons/General/User.svg" />
                </span>
              </span>
              <span class="navi-text">Account Information</span>
            </a>
          </div>
          <hr>
          <div class=" navi-item mb-2">
            <a class="navi-link py-4" @click="setActiveTab" style="cursor:pointer" data-tab="1" data-toggle="tab"
              role="tab" aria-selected="false">
              <span class="navi-icon mr-2">
                <span class="svg-icon">
                  <inline-svg src="media/svg/icons/Communication/Shield-user.svg" />
                </span>
              </span>
              <span class="navi-text">Change Password</span>
              <span class="navi-label">
              </span>
            </a>
          </div>
          <hr>
        </div>
      </div>
      <!-- </div> -->
    </div>

    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs" v-model="tabIndex">
        <b-tab active>
          <KTPersonalInformation></KTPersonalInformation>
        </b-tab>


        <b-tab>
          <KTChangePassword></KTChangePassword>
        </b-tab>

      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import KTDropdown2 from "@/view/content/dropdown/Dropdown2";
import KTPersonalInformation from "@/view/pages/profile/profile-comp/PersonalInformation";
import KTChangePassword from "@/view/pages/profile/profile-comp/ChangePassword";

export default {
  name: "custom-page",
  components: {
    // KTDropdown2,
    KTPersonalInformation,
    KTChangePassword,
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  mounted() {
    this.$refs.tabs.click();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile 1" }]);

  },
  methods: {
    /**
    * Set current active on click
    * @param event
    */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
 
      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated"]),
    picture() {
      if (this.isAuthenticated) {
        return process.env.VUE_APP_BACKEND_ENDPOINT + this.currentUser.userData.avatar;
      } else {
        return process.env.VUE_APP_BACKEND_ENDPOINT + "images/avatar.png";
      }
    },
    getFullName() {
      if (this.currentUser.userData) {
        return this.currentUser.userData.name;
      } else {
        return "";
      }
    }
  }
};
</script>
<style scoped>
.navi.navi-bold .navi-item .navi-link .navi-text {
  width: 155px;
  height: 26px;
  left: 23px;
  top: 17px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  /* identical to box height */


}

.navi.navi-active .navi-item .navi-link.active {
  background-color: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
}
</style>